
import React, { useEffect, useState } from "react";
import { createClient } from "../../../lib/helpers";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({

  catSpan: {
    display: "inline-block",
    flexdirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "7px",

    background: "#FFFFFF",
    border: "0.5px solid #DBE3EF",
    boxSizing: "border-box",
    boxShadow: "0px 2px 4px rgba(64, 64, 64, 0.1)",
    borderRadius: "4px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    textAlign: "center",
    color: "#50586C",
  },
  catContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "8px",
  },
}));

export default function CategoryBlock(props) {
  const { catTitle } = props;
  const classes = useStyles();

  return (
    <>
      <Grid className={classes.catContainer} item>
        {catTitle && catTitle.map(items => (
          <span className={classes.catSpan}>{items.title} </span>
        ))}
      </Grid>
    </>
  )


}

