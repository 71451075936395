import React, { useEffect, useState } from "react";
import { createClient } from "../../../lib/helpers";

const client = createClient();

export default function AuthorName(props) {

  const { person } = props;

  //console.log(props)

  const [authors, setAuthors] = useState([]);

  useEffect(() => {
    (async () => {
      //await getAuthors();
    })();
  }, []);

  var getAuths = async function () {

    //const paramsAuth = {
    //  pageType: "people",
    //  id: person,
    //}
    //const queryAuth = '*[_type == $pageType && _id==$id]{_id,name}'

    //return client.fetch(queryAuth, paramsAuth)
    //  .then((response) => response)
    //  .then((responseJson) => { return responseJson });
  }

  var getAuthors = async function () {
    //var auths = null //await getAuths();
    //setAuthors(auths);

  }

  return (
    <>

      <span>{props.name && props.name}</span>

    </>
  )

}

