import React, { useEffect, useState } from "react";
import { buildImageObj, publishDate, createClient } from "../../../lib/helpers";
import { imageUrlFor } from "../../../lib/image-url";
import AuthorName from "./authorName";
import CategoryBlock from "./categoryBlock";
import { makeStyles } from "@material-ui/core/styles";
import BasePortableText from "@sanity/block-content-to-react";
import clientConfig from "../../../../client-config";
import serializers from "../../serializers";
import { Grid, Button, Typography } from "@material-ui/core";
import ContainerForLinks from "../../containerForLinks";
import Container from "../../container";

const client = createClient();

const useStyles = makeStyles((theme) => ({
  cardList: {
    // paddingTop: '33px',
    // paddingBottom: '48px'
    marginTop: "0",
    marginBottom: "0",
  },
  card: {
    display: "flex",
    // flex: 1,
    borderRadius: "8px",
    "&:hover $title": {
      color: "#8C8CA1",
    },
    "&:hover $svg": {
      stroke: "#FF0F5F",
    },
    [theme.breakpoints.down("md")]: {
      padding: "8px !important",
    },
    [theme.breakpoints.up("md")]: {
      padding: "12px !important",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "15px !important",
    },
  },
  innerCard: {
    width: "100%",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    filter: "drop-shadow(0px 2px 4px rgba(64, 64, 64, 0.1))",
    "&:hover": {
      boxShadow: "0px 10px 30px 1.6px #4646462b",
      filter: "none",
    },
    position: "relative",
  },
  title: {
    fontWeight: "bold",
    paddingBottom: "12px",
    lineHeight: "27px",
    fontSize: "18px",
    color: "#262641",
    wordWrap: "break-word",
    maxHeight: "4.6em",
    WebkitLineClamp: 3,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
  },
  buttonText: {
    color: "#3C455C",
    marginLeft: "8px",
  },
  buttonHolder: {
    alignItems: "center",
  },
  imageHolder: {
    paddingBottom: "24px",
  },
  textHolder: {
    paddingLeft: "1.5em",
    paddingRight: "1.5em",
  },
  titleHolder: {
    paddingBottom: "24px",
    paddingLeft: "1.5em",
    paddingRight: "1.5em",
  },
  svg: {
    "&:hover": {
      stroke: "#FF0F5F",
    },
  },
  catSpan: {
    display: "inline",
    flexdirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
    width: "78px",
    height: "32px",
    left: "0px",
    top: "0px",
    background: "#FFFFFF",
    border: "0.5px solid #DBE3EF",
    boxSizing: "border-box",
    boxShadow: "0px 2px 4px rgb(64 64 64 / 10 %)",
    borderRadius: "4px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    textAlign: "center",
    color: "#50586C",
    marginRight: ".6em",
  },
  contentHolder: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  artImage: {
    width: "100%",
    display: "block",
    position: "relative",
    borderRadius: "8px 8px 0 0",
    backgroundColor: "lightGrey",
  },
  authorTextHolder: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "400",
    bottom: "3.5em",
    left: "1.5em",
    padding: "1.5em",
  },
  authorName: {
    color: "#3C455C",
    fontStyle: "italic",
  },
  publishedDate: {
    color: "#3C455C",
    fontWeight: "normal",
    opacity: "0.75",
    paddingLeft: "1.5em",
  },
  readMoreContainer: {
    marginTop: "auto",
    bottom: "1.4em",
    color: "#3C455C",
    fontWeight: "600",
    letterSpacing: "0.04em",
    padding: " 0 1.5em 1.5em 1.5em",
    left: "1.5em",
  },
  customLinkStyling:{
    height: "100%",
    textDecoration: "none",
    display: "flex",
    flexDirection: "column",
  }
}));

const pageType = "post";

export default function LatestArticlesGroup(props) {
  const { articleCount, categories, allArticles, noSpacer,
    setShouldHideRelatedContent, title, CategoryMultiSelect } = props;

  const [state, setState] = useState([]);

  const [loaded, setLoaded] = useState(false)

  const multipleCategories = CategoryMultiSelect && CategoryMultiSelect.length > 0 ? true : false;


  //console.log("multipleCategories", multipleCategories, CategoryMultiSelect, CategoryMultiSelect.length)

  const filterResults = (results, categories) => {

    //console.log("180", results, categories)
    var resArray = [];

    for (var i = 0; i < categories.length; i++) {

      //console.log("cat title", categories[i].title, categories.length)
      //catArray += filterByValue(results,categories[i].title)

      var targetCategory = categories[i].title;

      const numberOfRecordsToRetrieve = Math.ceil((articleCount / categories.length)/3)*3;

     
      // Sort the array based on the _updatedAt date in descending order
      var sortedArray = results
        .filter(item => item.categories.some(category => category.title === targetCategory))
        .sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt));

      const result = sortedArray.slice(0, numberOfRecordsToRetrieve);

      for (var j = 0; j < result.length; j++) {
        resArray.push(result[j])
      }


    }

    //console.log("resArray", resArray.length)

    if (multipleCategories) {

      //console.log("articleCategory", resArray)
      //const res = results.filter((article) => {
      //  return article.title !== title && article.categories.some((articleCategory) => {
      //    return categories.some((category) => {
      //      //console.log("articleCategory", articleCategory)
      //      return category.title === articleCategory.title;
      //    });
      //  });
      //});
      return resArray
    } else {
      const res = results?.sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt));

      //console.log("articleCategoryres", res)

      return res
    }
  }

  const removeNoCategories = (results) => {
    const res = results?.filter(result => result?.categories?.length !== 0)
    return res;
  }
  const filteredArticles = () => {

    
    try {
      if (articleCount) {
        
        return CategoryMultiSelect ? filterResults(allArticles?.nodes, CategoryMultiSelect)?.slice(0, articleCount) : removeNoCategories(allArticles?.nodes)?.slice(0, articleCount);
      } else {
        return CategoryMultiSelect ? filterResults(allArticles?.nodes, CategoryMultiSelect)?.nodes : removeNoCategories(allArticles?.nodes);
      }
    } catch (e) {
      return null;
    }


  }
  useEffect(() => {
    setState(filteredArticles())
  },[])

  useEffect(() => {
    setLoaded(true)
  }, [])

  // Hook to set state to stop the component displaying on info & articles pages if no relevant articles found
  useEffect(() => {
    if (setShouldHideRelatedContent && state.length === 0 && loaded){
      setShouldHideRelatedContent(true)
    }
  },[state])

  const classes = useStyles();

  return (
    <Container noSpacer={noSpacer}>
      <Grid container item alignItems="center" justify="center">
        <Grid className={classes.cardList} spacing={3} container>
          {state &&
            state.map((items) => {
              return (
                <Grid
                  item
                  className={classes.card}
                  md={4}
                  xs={12}
                  key={items.title}
                >
                  <Grid className={classes.innerCard}>
                    <ContainerForLinks customClassName={classes.customLinkStyling} reference={items} _type={pageType}>
                      <Grid className={classes.imageHolder}>
                        {items && <ShowImg mainImage={items.mainImage} />}
                      </Grid>
                      <Grid className={classes.contentHolder}>
                        <Grid className={classes.titleHolder}>

                          <CategoryBlock catTitle={items.categories} />
                        </Grid>
                        <Grid className={classes.textHolder}>
                          <span className={classes.title}>{items.title}</span>
                        </Grid>

                        <Grid className={classes.authorTextHolder}>
                          <span className={classes.authorName}>
                            By{" "}
                            {items.authors &&
                              items.authors.map((auth) => (
                                <AuthorName name={auth.people?.name} />
                              ))}
                          </span>

                          <span className={classes.publishedDate}>
                            {" "}
                            {publishDate(items.publishedAt)}
                          </span>
                        </Grid>
                        <Grid className={classes.readMoreContainer}>
                          <Grid container className={classes.buttonHolder}>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                className={classes.svg}
                                d="M19.75 11.7257L4.75 11.7257"
                                stroke="#200E32"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                className={classes.svg}
                                d="M13.7002 5.70131L19.7502 11.7253L13.7002 17.7503"
                                stroke="#200E32"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <Typography
                              variant="button"
                              className={classes.buttonText}
                            >
                              Read More
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </ContainerForLinks>
                  </Grid>
                </Grid>
              );
            })}
        </Grid>
      </Grid>
    </Container>
  );
}

function ShowImg(props) {
  const { mainImage } = props;

  const classes = useStyles();

  try {
    var imgObj = buildImageObj(mainImage);

    return (
      <img
        src={imageUrlFor(imgObj.asset)
          .width(370)
          .height(208)
          .fit("crop")
          .auto("format")
          .url()}
        className={classes.artImage}
      />
    );
  } catch (e) {
    return (
      <img
        src="https://cdn.sanity.io/images/k8qzcdw3/production/3d66df596b8abcbf5eb8d21f7213ff405a403fa2-656x350.jpg"
        height="208"
        width="370"
        className={classes.artImage}
      />
    );
  }
}
